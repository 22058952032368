<template>
  <vx-card title="Setting">
    <div clss="vx-row">
      <data-table :baseUrl="this.baseUrl"></data-table>
    </div>
  </vx-card>
</template>
<script>
import DataTable from "./DataTable.vue";
export default {
  components: {
    DataTable,
  },
  data() {
    return {
      baseUrl: "/api/account/v1/setting",
    };
  },
  methods: {},
  mounted() {},
  watch: {},
};
</script>